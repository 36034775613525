import React from 'react'
import Img from "gatsby-image";
import {StaticQuery, graphql} from 'gatsby';

export default() => (
  <StaticQuery query={
    graphql `
      query {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { path: { eq: "/bios" } }}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                path
                image {
                  childImageSharp {
                    fixed(width : 250) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              html
            }
          }
        }
      }
    `
  } render={data => (
    <div>
        {
          data.allMarkdownRemark.edges.map(({node: post}) => (
            <div className="bio" key={post.id}>
                <div className="container">
                <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className='bio-internal'>
                  <h4 className="is-size-4">{post.frontmatter.title}</h4>
                  <Img className="bio-img" fixed={post.frontmatter.image.childImageSharp.fixed}/>
                  <div className="bio-content" dangerouslySetInnerHTML={{
                      __html: post.html
                    }}/>
                    <div className='has-text-centered'><a className="button is-primary" href='/contact'>Contact {post.frontmatter.title} &rarr;</a></div>
                    </div>
                </div>
          </div></div></div>))
        }

    </div>

  )} />)
