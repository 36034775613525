import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'
import Bios from '../components/Bios'

const AboutPage = ({data}) => {
  const post = data.about

  return (<Layout>

    <div className="primary-contain">
    <Hero headerimg={post.frontmatter.image.childImageSharp.fluid}/>
    <div className="secondary-contain">
      <section>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-3 has-text-weight-bold">
                {post.frontmatter.title}
              </h1>
              <div className="index-content" dangerouslySetInnerHTML={{
                  __html: post.html
                }}/>
              <div className="has-text-centered down-2">
              <h2 className="is-size-3 has-text-weight-bold" id="team">
                Our Team
              </h2>
            </div>
            </div>
          </div>
        </div>

        <Bios />
      </section>
    </div>
  </div>

  <ContactForm/>
  </Layout>)
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPage

export const aboutPageQuery = graphql `
  query AboutPage {

    about: markdownRemark(frontmatter : {
      path: {
        eq: "/about"
      }
    }) {
      id
      frontmatter {
        path
        title
        image {
          childImageSharp {
            fluid(maxWidth : 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
